
// Imports

import { id, log, assign } from 'helpers';


//
// Sequence Manager
//

// Options

var undoSigil = 'U';
var requiredSequenceLength = 6;


// State

var state = {
  sequence: [],
  completed: false,
  onSelected: id,
  onChanged: id
};


// Functions

function matchSigil (targetSigil) {
  return function () {
    return $(this).data('sigil') === targetSigil;
  };
}

function updateState () {
  state.completed = getSequenceLength() >= requiredSequenceLength;
  state.onChanged(state.sequence);
}

function pushSigil (sigil) {
  if (sigil !== undoSigil) {
    state.lastSelectedSigil = sigil;
  }

  state.sequence.push(sigil);
  updateState();
}

function pushUndoSigil () {
  if (getSequenceLength() > 0) {
    pushSigil(undoSigil);
    updateState();
  } else {
    log("Can't undo - nothing in sequence");
  }
}

function popSigil () {
  var releasedSigil = state.sequence.pop();
  var $releasedWord = $allWords.filter(matchSigil(releasedSigil));
  updateState();
}

function selectWordIfAvailable () {
  var $button = $(this);
  var selected = $button.hasClass(selectedClass);

  if (selected) {
    log("Can't double-select", $button.data('sigil'));
  } else if (state.completed) {
    log("Can't select when sequence is full");
  } else {
    pushSigil( $button.data('sigil') );
    $button.addClass(selectedClass);
  }
}

function getSequenceLength (length = 0) {
  for (var i in state.sequence) {
    if (state.sequence[i] === undoSigil) {
      length--;
    } else {
      length++;
    }
  } return length;
}

function getFullSequence () {
  return state.sequence;
}

function getReducedSequence () {
  var seq = [];
  for (var i in state.sequence) {
    if (state.sequence[i] === undoSigil) {
      seq.pop();
    } else {
      seq.push(state.sequence[i]);
    }
  } return seq;;
}

function getLastSelectedSigil () {
  var seq = getReducedSequence();
  return seq[seq.length - 1];
}

function matchesLastSelectedWord (word) {
  return word.sigil === getLastSelectedSigil();
}


// Interface

export default {
  push:      pushSigil,
  undo:      pushUndoSigil,
  length:    getSequenceLength,
  onChanged: assign(state, 'onChanged'),
  getFullSequence,
  getReducedSequence,
  matchesLastSelectedWord
};

