
// Imports

import { log, searchWithin } from 'helpers';
import { initWidget }        from 'widgets';
import { initController }    from 'controllers';


// DOM Ready

$(function () {

  // Invoke widgets
  $('[data-widget]').each(initWidget);

  // Invoke controllers
  $('[data-controller]').each(initController);

  // Global behaviour
  // ... none yet

});

