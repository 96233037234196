
// Imports

import { id, log, assign } from 'helpers';


//
// Sequence Selector Widget
//

export default function SequenceSelectorWidget ($host, config) {

  log('New SequenceSelectorWidget');

  // Options
  var undoSigil     = config.undoSigil || 'U';
  var selectedClass = 'is-selected';
  var disabledClass = 'is-disabled';
  var requiredSequenceLength = 6;

  // State
  var state = {
    sequence: [],
    completed: false,
    onSelected: id,
    onChanged: id
  };

  // Functions
  function matchSigil (targetSigil) {
    return function () {
      return $(this).data('sigil') === targetSigil;
    };
  }

  function updateState () {
    state.completed = state.sequence.length >= requiredSequenceLength;
    state.onChanged(state.sequence);
  }

  function pushSigil (sigil) {
    state.sequence.push(sigil);
    updateState();
  }

  function pushUndoSigil () {
    pushSigil(undoSigil);
    updateState();
  }

  function popSigil () {
    var releasedSigil = state.sequence.pop();
    var $releasedWord = $allWords.filter(matchSigil(releasedSigil));
    $releasedWord.removeClass(selectedClass);
    updateState();
  }

  function selectWordIfAvailable () {
    var $button = $(this);
    var selected = $button.hasClass(selectedClass);

    if (selected) {
      log("Can't double-select", $button.data('sigil'));
    } else if (state.completed) {
      log("Can't select when sequence is full");
    } else {
      pushSigil( $button.data('sigil') );
      $button.addClass(selectedClass);
    }
  }

  function getWordlist () {
    return $allWords.map(function () { return $(this).data('sigil'); }).toArray();
  }

  // Listeners
  $allWords.on('click', selectWordIfAvailable);

  // Interface
  this.onWordSelected    = assign(state, 'onSelected');
  this.onSequenceChanged = assign(state, 'onChanged');

}

