
// Includes

import { id, log, delay, tween } from 'helpers';


// Constants

const equilateralRatio = Math.sqrt(3)/2;
const DEBUG_CLICK_TO_SHOW = false;


//
// Lines Effect
//

export default function LinesEffectWidget ($host, config) {

  // Set up canvas
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  var w = canvas.width = window.innerWidth;
  var h = canvas.height = window.innerHeight;

  // Options
  var stackHeight = 5;
  var animationSpeed = 1000;
  var triangleHeight = Math.floor(h/stackHeight);
  var triangleEdge = heightToEdge(triangleHeight);

  // Helpers
  function edgeToHeight (edge)   {
    return equilateralRatio * edge;
  }

  function heightToEdge (height) {
    return (1/equilateralRatio) * height;
  }

  function drawLines (thickness = 1) {
    if (thickness < 0.1) { return; } // Drawing engine can't deal with zero-width lines
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = 'white';
    ctx.lineWidth = thickness;

    // Horizontal lines
    ctx.beginPath();
    var numRows = stackHeight + 1;
    var offset = 0.5 * triangleHeight;
    for (var i = 0; i < numRows; i++) {
      var y = i * triangleHeight - offset;
      ctx.moveTo(0, y);
      ctx.lineTo(w, y);
    }

    // Down-right sloping lines
    var numSlopes = Math.floor(2 * w/triangleEdge);
    for (var i = 0; i < numSlopes * 2.2; i++) {
      var x1 = i * triangleEdge - triangleEdge * numRows/2 - 50;
      var x2 = x1 + triangleEdge/2 * numRows - 50;
      ctx.moveTo(x1, -offset);
      ctx.lineTo(x2, h + offset);
      var x3 = x1 - triangleEdge/2 * numRows - 50;
      ctx.moveTo(x1, -offset);
      ctx.lineTo(x3, h + offset);
    }

    ctx.closePath();
    ctx.stroke();
  }

  function clear () {
    $host.hide();
  }

  function drawStep () {
    drawLines(this.x * triangleHeight * 0.7);
  }

  function animate (duration, start, end, complete = id) {
    $host.show();
    $({ x: start }).animate({ x: end }, { step: drawStep, duration, complete });
  }

  function animateOn (time = animationSpeed, dest = 1) {
    animate(time, 0, dest);
  }

  function animateOff (time = animationSpeed, origin = 1) {
    animate(time, origin, 0, clear);
  }

  function animateOff_ (time = animationSpeed, origin = 1) {
    return function () {
      animate(time, origin, 0, clear);
    }
  }

  // Init
  $(canvas).css({ position: 'fixed', left: 0, top: 0, height: '100%' }).appendTo($host);

  // Debug
  if (DEBUG_CLICK_TO_SHOW) {
    $(canvas).on('click', function () {
      animateOn(animationSpeed); delay(animationSpeed, animateOff_(animationSpeed));
    });
  } else {
    clear();
  }

  // Interface
  this.on    = function (t, λ = id) { animateOn(t);  delay(t, λ); };
  this.off   = function (t, λ = id) { animateOff(t); delay(t, λ); };
  this.onOff = function (time = animationSpeed) { animateOn(time); delay(time, animateOff_(time)); };
  this.hide  = function () { $host.hide(); };
}

