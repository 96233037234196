
// Imports

import { id, log, assign, Pane } from 'helpers';
import { getInstanceFrom } from 'widgets';


// Instructions Pane Sub-controller

export default Pane(function InstructionsPane ($host, $$) {

  // Cache
  var $button = $('button', $host);
  var $box    = $('.box',   $host);

  // Widgets
  var unfolder = getInstanceFrom($('[data-widget="unfolding-effect"]', $host));

  // Options
  var animTime = 600;

  // State
  var state = {
    onButtonClick: id
  };

  // Functions
  function forceButtonClick () {
    $button.click();
  }

  function reveal (λ = id) {
    $host.fadeIn(animTime, function () {
      unfolder.setDimensions($box[0].getBoundingClientRect());
      unfolder.play(function (λ) {
        TweenMax.to($box, animTime/1000, { autoAlpha: 1, onComplete: λ});
      });
    });
  }

  function conceal (λ = id) {
    $host.fadeOut(animTime, λ);
  }

  // Listeners
  $button.on('click', function () { state.onButtonClick(); });

  // Interface
  this.onReady = assign(state, 'onButtonClick');
  this.forceButtonClick = forceButtonClick;
  this.conceal = conceal;
  this.reveal = reveal;
});

