
// Imports

import { id, log } from 'helpers';


//
// Elements Loader
//

export default function ElementsLoaderWidget ($host, $$) {

  var $elements = $host.children();

  // Functions
  function flick () {
    TweenMax.staggerFromTo(
      $elements, 0.4,
      { alpha: 1, scale: 1.00, repeat: 1, yoyo: true },
      { alpha: 0, scale: 1.05, repeat: 1, yoyo: true },
      0.2
    );
  }

  // Interface
  this.flick = flick;

}
