
// Helper Functions

export function id (it) {
  return it;
}

export function log (first) {
  console.log.apply(console, arguments);
  return first;
}

export function searchWithin ($region) {
  return function (selector) {
    return $region.find(selector);
  };
}

export function flip (λ) {
  return function (a, b) {
    return λ(b, a);
  }
};

export function tween (from, to, duration, step, done = id) {
  $({ x: from }).animate({ x: to }, {
    duration: duration,
    complete: done,
    step: function () {
      step(this.x);
    }
  });
}

export function assign (object, key) {
  return function (λ) {
    object[key] = λ;
  };
}

export function padTwo (str) {
  return (str.length < 2) ? "0" + str : str;
}

export function hex (decimal) {
  return padTwo(Math.floor(decimal).toString(16));
}

export function rgb ([r,g,b]) {
  return "#" + hex(r*255) + hex(g*255) + hex(b*255);
}

export function lerp (t, a, b) {
  return a + t * (b - a);
}

export function lerpColor (t, [ar,ag,ab], [br,bg,bb]) {
  return [ lerp(t, ar, br), lerp(t, ag, bg), lerp(t, ab, bb) ];
}

export function copyColor ([r,g,b]) {
  return [r,g,b];
}

export function normaliseRGB ([r,g,b]) {
  return [r/255,g/255,b/255];
}

export function contains (list, needle) {
  return list.indexOf(needle) > -1;
}

export function padTwo (n) {
  return (n < 10) ?  "0" + n : String(n);
}

export function randomRange (min, max) {
  return min + (max - min) * Math.random();
}

export function add (a, b) {
  return a + b;
}

export function avg (list) {
  return list.reduce(add, 0) / list.length;
}


// Derived functions

export var delay = flip(setTimeout);


// Decorators

export function Pane (PaneConstructor) {
  return function ($host, searchFn) {
    var instance = new PaneConstructor($host, searchFn);
    instance.show = instance.show || function () { $host.show(); };
    instance.hide = instance.hide || function () { $host.hide(); };
    return instance;
  }
};


// Export math functions

export var floor = Math.floor;

