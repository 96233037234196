
// Imports

import { id, log, assign, floor } from 'helpers';

const ZIG_A = 0x1;
const ZIG_B = 0x2;
const ZAG_A = 0x3;
const ZAG_B = 0x4;

const CONSTRAIN_VERTICALLY = true;
const HEIGHT_CONTRAIN_THRESHOLD = 600;


//
// Triangle Renderer
//

export default function TriangleRenderer ($host, config) {

  // Setup
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  $host.append(canvas);

  // Triangular math helpers
  var tr = Math.sqrt(3) / 2;
  var sideToHeight = (x) => x * tr;
  var heightToSide = (x) => x / tr;

  // Options
  var selectedClass = 'is-selected';
  var numRows = config.rows || 4;
  var trianglesPerRow = config.trianglesPerRow || 5;
  var rows = numRows + 1;
  var cols = trianglesPerRow + 1;
  var activeRegionAspect = numRows * Math.sqrt(3) / (trianglesPerRow + 1);
  var inherentWidth = 1000 * trianglesPerRow/7;
  var inherentFontSize = inherentWidth * 35/1000;
  var sidePadding = 10;

  // State
  var state = {
    mouse: { x: 0, y: 0 },
    layout: {},
    running: true,
    onSelected: id,
    lastSeenSelectables: [],
    textboxes: generateTextBoxes(numRows * trianglesPerRow)
  };

  // Functions
  function recalculateLayout () {
    var maxUsableWidth  = state.layout.maxUsableWidth  = window.innerWidth  - sidePadding * 2;
    var maxUsableHeight = state.layout.maxUsableHeight = (window.innerHeight - $host[0].offsetTop) * 4/5;

    if (window.innerHeight < HEIGHT_CONTRAIN_THRESHOLD) {
      state.layout.activeRegionWidth  = maxUsableWidth;
      state.layout.activeRegionHeight = maxUsableWidth * activeRegionAspect;
    } else {
      if ((maxUsableHeight / maxUsableWidth) <= activeRegionAspect) {
        state.layout.activeRegionHeight = maxUsableHeight;
        state.layout.activeRegionWidth  = maxUsableHeight / activeRegionAspect;
      } else {
        state.layout.activeRegionWidth  = maxUsableWidth;
        state.layout.activeRegionHeight = maxUsableWidth * activeRegionAspect;
      }
    }

    state.layout.scaleFactor   = inherentWidth / state.layout.activeRegionWidth;
    state.layout.colWidth      = state.layout.activeRegionWidth / (trianglesPerRow + 1);
    state.layout.triHeight     = state.layout.activeRegionHeight / numRows;
    state.layout.triSideLength = heightToSide(state.layout.triHeight);
    state.layout.offsetTop     = state.layout.triHeight / 2;
    state.layout.offsetLeft    = (window.innerWidth - state.layout.activeRegionWidth) / 2;

    canvas.width  = window.innerWidth;
    canvas.height = state.layout.activeRegionHeight + state.layout.triHeight;

    $host.height(state.layout.activeRegionHeight + state.layout.triHeight);
    render();
  }

  function generateTextBoxes (n, boxes = []) {
    for (var i = 0; i < n; i++) {
      boxes.push( $('<p></p>')
        .addClass('label')
        .addClass( i % 2 ? 'up' : 'down' )
        .attr('data-ix', i)
        .append( $('<span></span>') )
        .appendTo($host));
    } return boxes;
  }

  function updateTextBox (ix, thing) {
    var [ x, y ] = getTriangleCenterByIndex(ix);
    var width = state.layout.colWidth * 1.5;
    var styles = {
      top: y,
      left: x,
      width: width,
      fontSize: (inherentFontSize / state.layout.scaleFactor) + 'px',
      marginLeft: width / -2
    };

    state.textboxes[ix]
      .css(styles)
      .toggleClass(selectedClass, thing.selected)
      .children().first().text(thing.word);
  }

  function getTriangleCenterByIndex (ix) {
    var row  = floor(ix/trianglesPerRow);
    var col  = (ix % trianglesPerRow + 1);
    var type = getZigOrZag(col, row);
    var offset = state.layout.triHeight/2 + (type ? 1 : -1) * state.layout.triHeight * 0.4;

    return [
      state.layout.offsetLeft + col * state.layout.colWidth,
      state.layout.offsetTop  + row * state.layout.triHeight + offset
    ];
  }

  function clear () {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  function drawGrid () {
    ctx.beginPath();

    for (var row = 0; row < rows; row++) {
      ctx.moveTo(0, state.layout.offsetTop + row * state.layout.triHeight);
      ctx.lineTo(state.layout.activeRegionWidth, state.layout.offsetTop + row * state.layout.triHeight);
    }

    for (var col = 0; col < cols; col++) {
      ctx.moveTo(col * state.layout.colWidth, 0);
      ctx.lineTo(col * state.layout.colWidth, state.layout.activeRegionHeight + state.layout.offsetTop * 2);
    }

    ctx.closePath();
    ctx.stroke();
  }

  function getPixelOffset (col, row) {
    return [ col * state.layout.colWidth + state.layout.offsetLeft, state.layout.offsetTop + row * state.layout.triHeight ];
  }

  function calculateWhichBox (x, y) {
    return [ floor(x*cols), floor(y*numRows) ];
  }

  function getTriangleIndex (x, y) {
    var which = getTriangleHalf(x, y);
    var [ i, m ] = calculateWhichBox(x, y);

    if ( i < 0
      || i > trianglesPerRow
      || i == 0 && !which
      || i == trianglesPerRow && which) {
      return -1;
    }

    return (m) * trianglesPerRow + i - (which ? 0 : 1);
  }

  function getZigOrZag (col, row) {
    return (row % 2 + col % 2) % 2;
  }

  function getTriangleHalf (x, y) {
    var [ col, row ] = calculateWhichBox(x, y);
    var bx    = (x * cols - col);
    var by    = (y * numRows - row);
    var way   = (row % 2 + col % 2) % 2;
    var which = way ? bx - by <= 0 : bx + by <= 1;
    return !which;
  }

  function calculateTriangleType (x, y) {
    var [ col, row ] = calculateWhichBox(x, y);
    var bx    = (x * cols - col);
    var by    = (y * numRows - row);
    var way   = getZigOrZag(col, row);
    var which = way ? bx - by <= 0 : bx + by <= 1;

    if ( way &&  which) { return ZAG_A; }
    if ( way && !which) { return ZAG_B; }
    if (!way &&  which) { return ZIG_A; }
    if (!way && !which) { return ZIG_B; }
  }

  function highlightBox ({ x, y }) {
    if (y < 0 || x < 0 || y > 1 || x > 1) { return; }
    var [ col,  row ] = calculateWhichBox(x, y);
    var [ left, top ] = getPixelOffset(col, row);
    ctx.fillStyle = '#ddd';
    ctx.fillRect(left, top, state.layout.colWidth, state.layout.triHeight);
  }

  function highlightHalfTri ({ x, y }) {
    if (y < 0 || x < 0 || y > 1 || x > 1) { return; }
    var [ col,  row ] = calculateWhichBox(x, y);
    var [ left, top ] = getPixelOffset(col, row);

    // Draw it
    ctx.beginPath();
    ctx.fillStyle = '#ccc';

    switch (calculateTriangleType(x, y)) {
      case ZIG_A:
        ctx.moveTo(left + state.layout.colWidth, top);
        ctx.lineTo(left, top);
        ctx.lineTo(left, top + state.layout.triHeight);
        break;

      case ZIG_B:
        ctx.moveTo(left + state.layout.colWidth, top);
        ctx.lineTo(left + state.layout.colWidth, top + state.layout.triHeight);
        ctx.lineTo(left, top + state.layout.triHeight);
        break;

      case ZAG_A:
        ctx.moveTo(left, top);
        ctx.lineTo(left, top + state.layout.triHeight);
        ctx.lineTo(left + state.layout.colWidth, top + state.layout.triHeight);
        break;

      case ZAG_B:
        ctx.moveTo(left, top);
        ctx.lineTo(left + state.layout.colWidth, top);
        ctx.lineTo(left + state.layout.colWidth, top + state.layout.triHeight);
        break;
    }

    ctx.closePath();
    ctx.fill();
  }

  function highlightIndex ({ x, y }) {
    var ix = getTriangleIndex(x, y);
    var [ col,  row ] = calculateWhichBox(x, y);
    var [ left, top ] = getPixelOffset(col, row);

    ctx.fillStyle = 'black';
    ctx.font = '10px monospace';
    ctx.textAlign = 'center';
    ctx.fillText(ix, left + state.layout.colWidth/2, top, state.layout.colWidth);
  }

  function drawEmptyTriangleByIndex (ix, text = "EMPTY") {
    drawTriangleByIndex(ix, false, text);
  }

  function drawFilledTriangleByIndex (ix, text = "EMPTY") {
    drawTriangleByIndex(ix, true, text);
  }

  function drawTriangleByIndex (ix, fillOrStroke, text) {
    if (ix < 0) { return; }
    var row = floor(ix / trianglesPerRow);
    var col = ix % trianglesPerRow;
    var [ left, top ] = getPixelOffset(col, row);
    drawTriangleAtLocation(left, top, ix % 2, fillOrStroke);
    //drawTextInTriangle(text, left, top, ix % 2, fillOrStroke);
  }

  function drawTriangleAtLocation (left, top, upOrDown, fill) {
    composeTrianglePathAtLocation(left, top, upOrDown);

    if (fill) {
      var g = ctx.createLinearGradient(left, top, left, top + state.layout.triHeight);
      g.addColorStop(0, '#202020');
      g.addColorStop(1, '#919191');
      ctx.fillStyle = g;
      ctx.fill();
    }
  }

  function composeTrianglePathAtLocation (left, top, upOrDown) {
    ctx.beginPath();
    if (upOrDown) {
      ctx.moveTo(left, top);
      ctx.lineTo(left + state.layout.colWidth, top + state.layout.triHeight);
      ctx.lineTo(left + state.layout.triSideLength, top);
    } else {
      ctx.moveTo(left, top + state.layout.triHeight);
      ctx.lineTo(left + state.layout.colWidth, top);
      ctx.lineTo(left + state.layout.triSideLength, top + state.layout.triHeight);
    }
    ctx.closePath();
  }

  function drawTextInTriangle (text, left, top, upOrDown, selected) {
    var offset = state.layout.triHeight/3 * (upOrDown ? -1 : 1);
    var fontSize = inherentFontSize / state.layout.scaleFactor;
    ctx.fillStyle = selected ? 'white' : 'black';
    ctx.font = fontSize + 'px monospace';
    ctx.textAlign = 'center';
    ctx.textBaseline = upOrDown ? 'top' : 'bottom';
    ctx.fillText(
      text, left + state.layout.colWidth,
      top + state.layout.triHeight/2 + offset,
      state.layout.triSideLength * 0.6);
  }

  function drawDecorativeTriangles () {
    var trianglesPerScreenWidth = floor(window.innerWidth/state.layout.triSideLength) + 6;

    for (var row = -1; row < rows; row++) {
      var odd = row % 2;
      for (var i = 0; i < trianglesPerScreenWidth; i++) {
        var top  = row * state.layout.triHeight;
        var left = state.layout.colWidth * odd + (i - floor(trianglesPerScreenWidth/2)) * state.layout.triSideLength;
        composeTrianglePathAtLocation(state.layout.offsetLeft + left, state.layout.offsetTop + top);
        ctx.stroke();
      }
    }
  }

  function saveMouse ({ x, y }) {
    state.mouse.x = x;
    state.mouse.y = y;
  }

  function normaliseMouse (λ) {
    return function ({ pageX, pageY }) {
      λ.call(this, {
        x: (pageX - state.layout.offsetLeft)/state.layout.activeRegionWidth,
        y: (pageY - this.offsetTop - state.layout.offsetTop)/state.layout.activeRegionHeight
      });
    };
  }

  function normaliseTouch (λ) {
    return function ({ originalEvent }) {
      var { pageX, pageY }:event = originalEvent.touches[0];

      λ.call(this, {
        x: (pageX - state.layout.offsetLeft)/state.layout.activeRegionWidth,
        y: (pageY - this.offsetTop - state.layout.offsetTop)/state.layout.activeRegionHeight
      });

      event.preventDefault();
      return false;
    };
  }

  function dispatchSelectionFromCoords ({ x, y }) {
    var index = getTriangleIndex(x, y);
    state.onSelected(index);
  }

  function dispatchSelectionFromTextbox () {
    var index = $(this).data('ix');
    state.onSelected(index);
  }

  function renderSelectables (selectables) {
    state.lastSeenSelectables = selectables;
    render();
  }

  function DEBUG_renderActiveBounds () {
    ctx.strokeStyle = 'red';
    ctx.strokeRect(state.layout.offsetLeft, state.layout.offsetTop, state.layout.activeRegionWidth, state.layout.activeRegionHeight);
    ctx.strokeStyle = 'blue';
    ctx.strokeRect(sidePadding, state.layout.offsetTop, state.layout.maxUsableWidth, state.layout.maxUsableHeight);
    ctx.strokeStyle = 'black';
  }

  function cascadeRevealTextboxes () {
    TweenMax.staggerFromTo(state.textboxes, 0.5,
      { autoAlpha: 0 },
      { autoAlpha: 1 }, -0.03);
  }

  function render (λ = id) {
    clear();
    state.lastSeenSelectables.forEach(function (thing, ix) {
      if (thing.selected) {
        drawFilledTriangleByIndex(ix, thing.word);
      } else {
        drawEmptyTriangleByIndex(ix, thing.word);
      }
      updateTextBox(ix, thing);
    });

    drawDecorativeTriangles();
    // DEBUG_renderActiveBounds();
  }

  // Listeners
  window.addEventListener('resize', recalculateLayout, false);
  $host.on('mousemove', normaliseMouse(saveMouse));
  $host.on('click', normaliseMouse(dispatchSelectionFromCoords));
  // $host.on('touchend', normaliseTouch(dispatchSelectionFromCoords));

  // Init
  recalculateLayout();

  // Interface
  this.onTriangleSelected = assign(state, 'onSelected');
  this.recalculateLayout = recalculateLayout;
  this.renderWordListAsTriangles = renderSelectables;
  this.cascadeRevealTextboxes = cascadeRevealTextboxes;
}

