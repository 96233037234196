
// Imports

import { id, log, assign } from 'helpers';

function clientRectToPlainObject (clientRect) {
  return {
    top:      clientRect.top,
    left:     clientRect.left,
    right:    clientRect.right,
    bottom:   clientRect.bottom,
    width:    clientRect.width,
    height:   clientRect.height
  };
}


// Unfolding effect widget

export default function UnfoldingEffectWidget ($host, config) {

  // Dom
  var canvas  = document.createElement('canvas');
  var ctx     = canvas.getContext('2d');
  var $canvas = $(canvas);

  // Options
  var duration = 600;
  var size     = 500;

  // Setup
  canvas.width = size;
  canvas.height = size;

  $canvas.css({
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10
  });

  $host.append(canvas).css({
    position: 'absolute'
  });

  // State
  var state = {
    time: 0,
    stopped: false,
    onComplete: id
  };

  // Frame dispatch to rendering functions
  function frame () {
    state.time += 16;
    var p = (state.time/duration);

    if (p <= 1/3) {
      renderA(p * 3);
    } else if (p <= 2/3) {
      renderB((p - 1/3) * 3);
    } else if (p <= 1) {
      renderC((p - 2/3) * 3);
    } else {
      renderC(1);
      state.stopped = true;
      state.onComplete($host.hide.bind($host));
    }

    if (!state.stopped) {
      requestAnimationFrame(frame);
    }
  }

  // Rendering stages
  function renderA (p) {
    ctx.fillStyle = 'white';
    ctx.strokeStyle = '#555';
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(size, 0);
    ctx.lineTo(size/2, size/2 * p);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  function renderB (p) {
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(size, 0);
    ctx.lineTo(size * p, size * p);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  function renderC (p) {
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(size, 0);
    ctx.lineTo(size, size);
    ctx.lineTo(size - size * p, size * p);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  // Helper functions
  function setDimensions (rect) {
    var scroll = $(document).scrollTop();
    var bbox = clientRectToPlainObject(rect);
    $host.css(bbox);
    $host.css({ top: bbox.top + scroll });
  }

  function stop () {
    renderC(1);
  }

  // Init
  function init (λ) {
    $canvas.show();
    requestAnimationFrame(frame);
    state.onComplete = λ;
  }

  // Interface
  this.play = init;
  this.stop = stop;
  this.setDimensions = setDimensions;
  this.onComplete = assign(state, 'onComplete');
};

