
import { log } from 'helpers';

import ChosenWidget          from './chosen';
import LinesEffectWidget     from './lines-effect';
import ValidatorWidget       from './validator';
import GradientEffectWidget  from './gradient-effect';
import UnfoldingEffectWidget from './unfolding-effect';
import ElementsLoaderWidget  from './elements-loader';
import SequenceSelector      from './sequence-selector';
import TriangleRenderer      from './triangle-renderer';
import SelectorControls      from './selector-controls';


// Meta

var allWidgets = {};

var widgetId = (function (i = 0) {
  return function () {
    return 'x' + i++;
  };
}());


// Widgets Library

var Widgets = {
  'elements-loader':   ElementsLoaderWidget,
  'gradient-effect':   GradientEffectWidget,
  'line-effect':       LinesEffectWidget,
  'select':            ChosenWidget,
  'selector-controls': SelectorControls,
  'sequence-selector': SequenceSelector,
  'triangle-renderer': TriangleRenderer,
  'unfolding-effect':  UnfoldingEffectWidget,
  'validator':         ValidatorWidget,
};


// Functions

export function getInstanceFrom ($target) {
  var data = $target.data();

  // Error gauntlet
  if ($target.length < 1) {
    return log('Tried to get widget instance of empty selection:', $target.selector);
  }

  if (typeof data.widgetId === 'undefined') {
    return log("Element has no widget instance ID:", $target[0]);
  }

  if (!allWidgets[data.widgetId]) {
    return log("Tried to get widget instance from", $target[0], "but couldn't find one.");
  }

  // Finally, everything works
  return allWidgets[data.widgetId];
}

export function initWidget (ix, target) {
  var $this  = $(target),
      config = $this.data(),
      type   = config.widget;

  if (type && Widgets[type]) {
    var newId = widgetId();
    config.widgetId = newId;
    $this.data('widget-id', newId);
    allWidgets[newId] = new Widgets[type]($this, config);
  } else {
    log('Unknown widget:', type);
  }
}


// Expose widget library as namespace

export default Widgets;

