
// Imports

import { id, log, assign } from 'helpers';


//
// Selector Controls
//

export default function SelectorControls ($host, config) {

  // Cache
  var $undoButton = $('button[data-action="undo"]', $host);
  var $nextButton = $('button[data-action="next"]', $host);

  // Options
  var disabledClass = 'is-disabled';

  // State
  var state = {
    onUndo: id,
    onNext: id,
    nextEnabled: false
  }

  // Functions
  function setNextButtonState (isEnabled) {
    state.nextEnabled = isEnabled;
    $nextButton.toggleClass(disabledClass, !isEnabled);
  }

  function setUndoButtonState (isEnabled) {
    state.undoEnabled = isEnabled;
    $undoButton.toggleClass(disabledClass, !isEnabled);
  }

  function proceedIfEnabled () {
    if (state.nextEnabled) {
      state.onNext();
    } else {
      log('NOT YET');
    }
  }

  function dispatchUndoIfEnabled () {
    if (state.undoEnabled) {
      state.onUndo();
    } else {
      log('NO UNDO');
    }
  }

  // Listeners
  $nextButton.on('click', proceedIfEnabled);
  $undoButton.on('click', dispatchUndoIfEnabled);

  // Init
  setNextButtonState(false);
  setUndoButtonState(false);

  // Interface
  this.onUndoClicked = assign(state, 'onUndo');
  this.onNextClicked = assign(state, 'onNext');
  this.setNextAvailable = setNextButtonState;
  this.setUndoAvailable = setUndoButtonState;
  this.DEBUG_forceButtonClick = $nextButton.click.bind($nextButton);
}

