
// Imports

import { log } from 'helpers';
import 'chosen';


//
// Chosen Widget
//
// Just a wrapper for the 3rd-party plugin
//

export default function ChosenWidget ($host, config) {

  // Option
  var chosenOptions = {
    disable_search_threshold: 100,
    placeholder: "TEXT"
  };

  // Init
  $host.chosen(chosenOptions);

}

