
import { id, log, searchWithin } from 'helpers';

import TasterController from './taster';


// Controller Library

var Controllers = {
  taster: TasterController
};


// Functions

export function invoke (Controller, $region) {
  return new Controller($region, searchWithin($region), invoke);
}

export function initController (ix, target) {
  var $scope = $(target),
      type   = $scope.data('controller');

  if (type && Controllers[type]) {
    invoke(Controllers[type], $scope);
  } else {
    log('Unknown controller:', type);
  }
}


// Expose controller library as namespace

export default Controllers;

