
import { id, log } from 'helpers';
import { getInstanceFrom } from 'widgets';

import InstructionsPane from './instructions-pane';
import SelectorPane     from './selector-pane';
import ProcessingPane   from './processing-pane';
import ResultsPane      from './results-pane';

const IS_LOCALHOST = window.location.hostname === "localhost";
const DEBUG_sequence = [ 'E1', 'A2', 'A4', 'F1', 'E2', 'W3' ];
const TASTER_DEBUG_MODE = false && IS_LOCALHOST;


//
// Taster Controller
//
// The main interactive application
//

export default function TasterController ($host, $$, invoke) {

  log("New TasterController");

  // Pane Sub-controllers
  var pane = {
    instructions : invoke(InstructionsPane, $$('[data-app-pane="instructions"]')),
    selector     : invoke(SelectorPane,     $$('[data-app-pane="selector"]')),
    processing   : invoke(ProcessingPane,   $$('[data-app-pane="processing"]')),
    results      : invoke(ResultsPane,      $$('[data-app-pane="results"]'))
  };

  // Trans-pane Effects
  var linesEffect    = getInstanceFrom($$('[data-widget="line-effect"]'));
  var gradientEffect = getInstanceFrom($$('[data-widget="gradient-effect"]'));

  // Listeners
  pane.instructions.onReady(function () {
    pane.instructions.conceal();
    linesEffect.on(1000, function () {
      pane.selector.reveal();
      gradientEffect.show();
      linesEffect.hide();
    });
  });

  pane.selector.onSelectionComplete(function (sequence) {
    pane.selector.conceal()
    pane.processing.dispatchSelectionSequence(sequence);
    gradientEffect.transitionSelectorToProcessing(pane.processing.reveal);
  });

  pane.processing.onMessageAcknowledged(function (response) {
    pane.processing.conceal(pane.results.reveal);
    pane.results.populate(response);
    gradientEffect.transitionProcessingToResult(response);
  });

  pane.processing.onResponseReceived(function (results) {
    log(results);
    pane.results.populate(results);
  });

  // Init
  if (!TASTER_DEBUG_MODE) {

    pane.instructions.reveal();

  // Debug
  } else {

    pane.selector.reveal();
    // pane.processing.reveal();
    // pane.results.reveal();
    gradientEffect.show();
    // gradientEffect.transitionProcessingToResult({ keywords: ['air', 'earth'] });

    // setTimeout(function () { pane.instructions.forceButtonClick(); }, 100);
    // setTimeout(function () { pane.selector.DEBUG_setSequence(DEBUG_sequence); }, 1800);
    // setTimeout(function () { pane.selector.DEBUG_forceButtonClick(); }, 2600);
    // setTimeout(function () { pane.processing.dispatchSelectionSequence(DEBUG_sequence); }, 0);
    // setTimeout(function () { pane.processing.DEBUG_forceButtonClick(); }, 6200);
  }
}
