
// Imports

import { id, log, assign, Pane } from 'helpers';
import { getInstanceFrom } from 'widgets';


// Results Pane Sub-controller

export default Pane(function ResultsPane ($host, $$) {

  // Cache
  var $box            = $('.box', $host);
  var $printButton    = $('button', $host);
  var $elementMajor   = $('.elements-result .major', $host);
  var $elementMinor   = $('.elements-result .minor', $host);
  var $analysisBlurbs = $('.analysis-blurb .text', $host);
  var $elementIcons   = $('.diagram img', $host);

  // Widgets
  var unfolder = getInstanceFrom($('[data-widget="unfolding-effect"]', $host));

  // Options
  var animTime = 600;
  var markedClass = 'x';
  var markSuffix = '-x.';

  // State
  var state = {
    onComplete: id
  };

  function populateResultHeading ([ major, minor ], [ majorKey, minorKey ]) {
    $elementMajor.addClass(majorKey.toLowerCase()).text(major);
    $elementMinor.addClass(minorKey.toLowerCase()).text(minor);

    $elementIcons.each(function () {
      var element = this.className;
      var $this = $(this);

      if (!$this.hasClass(markedClass) && element !== majorKey && element !== minorKey) {
        var [ path, ext ] = $this.attr('src').split('.');
        $this.attr('src', path + markSuffix + ext);
        $this.addClass(markedClass);
      }
    });
  }

  function populateAnalysisText (analysis) {
    $analysisBlurbs.each(function (ix, el) {
      $(this).text( analysis[ix] );
    });
  }

  function populateResult (result) {
    populateResultHeading(result.elements, result.keywords);
    populateAnalysisText(result.analysis);
  }

  function reveal (λ = id) {
    $host.fadeIn(animTime, function () {
      unfolder.setDimensions($box[0].getBoundingClientRect());
      unfolder.play(function (λ) {
        TweenMax.to($box, animTime/1000, { autoAlpha: 1, onComplete: λ });
      });
    });
  }

  function conceal (λ = id) {
    $host.fadeOut(animTime, λ);
  }

  // Listeners
  $printButton.on('click', function () {
    window.print();
  });

  // Interface
  this.populate = populateResult;
  this.reveal = reveal;
  this.conceal = conceal;
});

