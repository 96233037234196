
// Imports

import { log } from 'helpers';


//
// Validator Widget
//

export default function ValidatorWidget ($host, config) {

  // Cache
  var $inputs = $('input', $host);

  // Options
  var DEBUG_BLOCK_FORMS = false;
  var errorClass = 'error';

  // State
  var state = {
    fail: false
  };

  // Methods
  function validate () {
    state.fail = false;
    $inputs.each(validateNonEmpty(state));
    return !state.fail;
  }

  function validateNonEmpty (state) {
    return function (ix, el) {
      var $target = $(el);
      if ($target.val().length < 1) {
        $target.focus().addClass(errorClass);
        state.fail = true;
      } else {
        $target.removeClass(errorClass);
      }
    }
  }

  function onSubmit () {
    return validate() && !DEBUG_BLOCK_FORMS;
  };

  // Listeners
  $host.on('submit', onSubmit);

}

