
// Imports

import { id, log, assign, contains, padTwo, Pane } from 'helpers';
import { getInstanceFrom } from 'widgets';
import sequencer from '../sequencer';


// Selector Pane Sub-controller

export default Pane(function SelectorPane ($host, $$) {

  // Dom Cache
  var $jsonScript = $('#word-data', $host);
  var $readout = $('<pre></pre>').css({ color: 'black', position: 'absolute', top: 0, left: 0, zIndex: 10 });

  // Widget Cache
  var controls  = getInstanceFrom($$('[data-widget="selector-controls"]'));
  var triangles = getInstanceFrom($$('[data-widget="triangle-renderer"]'));

  // Options
  var animTime = 600;
  var minimumSequenceLength = 6;
  var disabledClass = 'is-disabled';

  // Data
  var data = prepareWords(JSON.parse($jsonScript.text()));

  // State
  var state = {
    words: data,
    onComplete: id
  };

  // Functions
  function reveal (λ = id) {
    $host.fadeIn(animTime, λ);
    triangles.recalculateLayout();
    triangles.cascadeRevealTextboxes();
  }

  function conceal (λ = id) {
    $host.fadeOut(animTime, λ);
  }

  function prepareWords (rawWords) {
    var words = [];
    for (var key in rawWords) {
      var word = rawWords[key];
      words.push({ sigil: key, word: word, selected: false });
    } return words;
  }

  function triggerComplete () {
    if (sequencer.length() >= minimumSequenceLength) {
      state.onComplete(sequencer.getFullSequence());
    } else {
      log('Not yet');
    }
  }

  function onSequenceChanged (sequence) {
    var reducedSequence = sequencer.getReducedSequence();
    var complete = sequencer.length() >= minimumSequenceLength;
    var nonZero  = sequencer.length() >= 1;

    controls.setNextAvailable(complete);
    controls.setUndoAvailable(nonZero);

    state.words.forEach(function (word, ix) {
      word.selected = contains(reducedSequence, word.sigil);
    });

    $readout.text(DEBUG_serialiseWords(state.words));
    triangles.renderWordListAsTriangles(state.words);
  }

  function DEBUG_serialiseWords (words) {
    var str = "";
    words.forEach(function (word, ix) {
      str += '(' + padTwo(ix) + ')[' + word.sigil + ']{' + (word.selected ? 'X' : ' ') + '} ' + word.word + "\n";
    });
    return str;
  }

  function selectWordByIndex (ix) {
    var word = state.words[ix];

    if (ix < 0 || ix >= state.words.length) {
      return log('No such word:', ix);
    }

    if (word.selected) {
      if (sequencer.matchesLastSelectedWord(word)) {
        sequencer.undo();
        word.selected = false;
        return;
      } else {
        return log("Can't deselect out-of-sequence words");
      }
    }

    if (sequencer.length() >= minimumSequenceLength) {
      return log("Can't choose more than 6 sigils");
    }

    // Finally, select the word
    sequencer.push(word.sigil);
    word.selected = true;
  }

  // Listeners
  sequencer.onChanged(onSequenceChanged);
  triangles.onTriangleSelected(selectWordByIndex);
  controls.onUndoClicked(sequencer.undo);
  controls.onNextClicked(triggerComplete);

  // Init
  triangles.renderWordListAsTriangles(state.words);
  //$readout.appendTo($host)

  // Interface
  this.onSelectionComplete = assign(state, 'onComplete');
  this.reveal  = reveal;
  this.conceal = conceal;
  this.DEBUG_forceButtonClick = controls.DEBUG_forceButtonClick;

});
