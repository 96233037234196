
// Imports

import { id, log, assign, delay, randomRange, Pane } from 'helpers';
import { getInstanceFrom } from 'widgets';


// Debug

var DEBUG_SIMULATE_LATENCY = false;


// Selector Pane Sub-controller

export default Pane(function ProcessingPane ($host, $$) {

  // Cache
  var $nextButton = $('button[data-action="next"]', $host);
  var $box = $('.box', $host);

  // Options
  var ajaxEndpoint    = "taster.php";
  var disabledClass   = 'is-disabled';
  var ajaxModeKeyword = 'ajax';
  var animTime        = 600;

  // Widgets
  var unfolder = getInstanceFrom($('[data-widget="unfolding-effect"]', $host));
  var loader   = getInstanceFrom($('[data-widget="elements-loader"]',  $host));

  // State
  var state = {
    onComplete: id,
    onReceived: id,
    nextEnabled: false,
    result: null
  };

  // Functions
  function reveal (λ = id) {
    $host.fadeIn(animTime, function () {
      unfolder.setDimensions($box[0].getBoundingClientRect());
      unfolder.play(function (λ) {
        TweenMax.to($box, animTime/1000, { autoAlpha: 1, onComplete: λ });
        delay(1000, loader.flick);
      });
    });
  }

  function conceal (λ = id) {
    $host.fadeOut(animTime, λ);
  }

  function onResponseReceived (response) {
    var latency = DEBUG_SIMULATE_LATENCY ? randomRange(1000, 5000) : 0;

    delay(latency, function () {
      state.onReceived(response);
      state.result = response;
      setNextButtonState(true);
    });
  }

  function onServerError (jsxhr, errorMessage, error) {
    log(jsxhr.responseText);
  }

  function serialiseSequence (sequence) {
    return sequence.join(',');
  }

  function processSequence (sequence) {
    $.ajax({
      url: ajaxEndpoint,
      type: 'post',
      dataType: 'json',
      data: {
        sequence: serialiseSequence(sequence),
        mode: ajaxModeKeyword
      },
      success: onResponseReceived,
      error: onServerError
    });
  }

  function onNextButtonClick () {
    if (state.nextEnabled) {
      state.onComplete(state.result);
    } else {
      log('Still awaiting result from server');
    }
  }

  function setNextButtonState (isEnabled) {
    state.nextEnabled = isEnabled;
    $nextButton.toggleClass(disabledClass, !isEnabled);
  }


  // Listeners
  $nextButton.on('click', onNextButtonClick);
  setNextButtonState(false);

  // Interface
  this.onMessageAcknowledged = assign(state, 'onComplete');
  this.onResponseReceived = assign(state, 'onReceived');
  this.dispatchSelectionSequence = processSequence;
  this.reveal  = reveal;
  this.conceal = conceal;
  this.DEBUG_forceButtonClick = $nextButton.click.bind($nextButton);
});

