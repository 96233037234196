
// Includes

import { id, log, delay, avg, rgb, lerpColor, copyColor, normaliseRGB, tween } from 'helpers';


// Debug

const DEBUG_SHOW_COLOR_BALANCE = false;
const BREAKPOINT_BIGPHONE = 360;


//
// Oscillating gradient effect
//

export default function GradientEffectWidget ($host, config) {

  var webkitPrefix = '-webkit-';


  // Color palette

  var colors = {
    white: [1,1,1],
    black: [0,0,0],
    red:   normaliseRGB([201,16,50]),
    blue:  normaliseRGB([43,154,200]),
    green: normaliseRGB([56,182,46]),
    brown: normaliseRGB([140,93,47]),
    grey1: [0.45,0.45,0.45],
    grey2: [0.24,0.24,0.24]
  };

  if (DEBUG_SHOW_COLOR_BALANCE) {
    log('Color balance:');
    var colorKeys  = ['red','blue','green','brown'];
    var averages   = colorKeys.map(function (key) { return avg(colors[key]); });
    var masterAvg  = avg(averages);

    colorKeys.forEach(function (key, ix) {
      log(`${key}: ${averages[ix]} (${averages[ix] - masterAvg})`);
    });
  }

  // Tag with easing options so we can pass colors directly to tweening engine
  for (var k in colors) { colors[k].ease = Sine.easeInOut; }

  // Options
  var selToProcTransTime = 1;

  // State
  var state = {
    left: [1,1,1],
    right: [1,1,1],
    running: true,
    rotation: -45
  };

  // Functions
  function elementToColor (element) {
    switch (element) {
      case "air":   return colors.green;
      case "earth": return colors.brown;
      case "fire":  return colors.red;
      case "water": return colors.blue;
      default: log('No color for element:', element);
    }
  }

  function renderGradient (rotation, start, end, prefix = '') {
    //return `${prefix}linear-gradient(${rotation}deg, ${rgb(start)} 5%, ${rgb(end)} 95%)`;
    return `${prefix}linear-gradient(to bottom, ${rgb(start)} 5%, ${rgb(end)} 95%)`;
  }

  function setGradient () {
    //log(renderGradient(state.rotation, state.left.color, state.right.color));
    $host
      .css({ background: renderGradient(state.rotation, state.left, state.right, webkitPrefix) })
      .css({ background: renderGradient(state.rotation, state.left, state.right) });
  }

  // Tweens
  var turnSlowly =
    new TimelineMax({ paused: true, repeat: -1 })
    .fromTo(state, 20, { rotation: -90 }, { rotation: 270, ease: Linear.easeNone });

  // Init
  $host.css({
    background: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    display: 'none'
  });

  function frame () {
    setGradient();
    if (state.running) {
      requestAnimationFrame(frame);
    }
  } frame();

  if (config.elements) {
    var chosenColors= config.elements.split(',').map(elementToColor);
    TweenMax.to(state.left,  1, chosenColors[0]);
    TweenMax.to(state.right, 1, chosenColors[1]);
    $host.css({ position: 'absolute' }).show();
  }

  // Interface
  this.hide = function () { $host.hide(); };
  this.show = function () { $host.show(); };

  this.transitionSelectorToProcessing = function (λ) {
    TweenMax.to(state.left, selToProcTransTime, colors.grey1);
    TweenMax.to(state.right, selToProcTransTime, colors.grey2);
    // if (window.innerWidth > BREAKPOINT_BIGPHONE) { turnSlowly.play(); }
    delay(selToProcTransTime * 1000, λ);
  };

  this.transitionProcessingToResult = function (result) {
    var chosenColors = result.keywords.map(elementToColor);
    TweenMax.to(state.left,  3, chosenColors[0]);
    TweenMax.to(state.right, 3, chosenColors[1]);
  }

}
